import React, { useEffect } from 'react'
import Typed from 'typed.js'

const Motto = ({ strings }) => {
    useEffect(() => {

      // Options for the Typed object
      const options = {
        backDelay: 2400,
        loop: true,
        smartBackspace: true,
        strings: strings,
        typeSpeed: 35,
      };

      // New Typed instance
      const typed = new Typed('#motto', options);

      // Destroy Typed instance on unmounting the component to prevent memory leaks
      return () => {
        typed.destroy();
      };
    }, [strings]);

    return (
      <span id='motto' className='font-weight-bold'></span>
    );
};

export default Motto;