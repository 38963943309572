import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [
    "dynamicDeposit",
    "interestRateFormula",
    "interestRateTable",
    "periodInputField",
    "periodInputText",
    "prizePercentageField",
    "prizeRangeFields",
    "prizeRulesFields",
  ];

  testFormSetting({ currentTarget }) {
    const form = currentTarget.form;
    form.dataset.remote = true;

    Rails.fire(form, "submit");
    delete form.dataset.remote;
  }

  toggleMonthlyType({ currentTarget }) {
    const { value } = currentTarget;

    if (value !== "default") {
      this.dynamicDepositTarget.classList.remove("d-none");
    } else {
      this.dynamicDepositTarget.classList.add("d-none");
    }

    this.dynamicDepositTarget
      .querySelectorAll('input[type="number"]')
      .forEach((field) => (field.disabled = value === "default"));
  }

  togglePeriodType({ currentTarget }) {
    const { periodStep, periodLabel } = currentTarget.dataset;

    this.periodInputTextTargets.forEach(
      (field) => (field.innerText = periodLabel)
    );
    this.periodInputFieldTargets.forEach((field) => {
      field.step = periodStep;
      field.value = 0;
    });
  }

  togglePrizeType({ currentTarget }) {
    const { value } = currentTarget;

    switch (value) {
      case "percentage":
        this._togglePrizeSettingFields(this.prizeRangeFieldsTarget, "hidden");
        this._togglePrizeSettingFields(
          this.prizePercentageFieldTarget,
          "shown"
        );
        this._togglePrizeSettingFields(this.prizeRulesFieldsTarget, "shown");
        break;
      case "range":
        this._togglePrizeSettingFields(
          this.prizePercentageFieldTarget,
          "hidden"
        );
        this._togglePrizeSettingFields(this.prizeRangeFieldsTarget, "shown");
        this._togglePrizeSettingFields(this.prizeRulesFieldsTarget, "shown");
        break;
      default:
        this._togglePrizeSettingFields(
          this.prizePercentageFieldTarget,
          "hidden"
        );
        this._togglePrizeSettingFields(this.prizeRangeFieldsTarget, "hidden");
        this._togglePrizeSettingFields(this.prizeRulesFieldsTarget, "hidden");
        break;
    }
  }

  toggleInterestRateType({ currentTarget }) {
    const { value } = currentTarget;

    if (value !== "range") {
      this.interestRateTableTarget.classList.add("d-none");
      this.interestRateFormulaTarget.classList.remove("d-none");
    } else {
      this.interestRateFormulaTarget.classList.add("d-none");
      this.interestRateTableTarget.classList.remove("d-none");
    }
  }

  // private

  _togglePrizeSettingFields(field, status) {
    if (status === "shown") {
      field.classList.remove("d-none");
    } else {
      field.classList.add("d-none");
    }
  }
}
