document.addEventListener("turbolinks:load", () => {
  document.querySelectorAll('[data-form="modal"]').forEach((modal) => {
    modal.addEventListener("ajax:success", (event) => {
      const modalTemplate = document.querySelector('[data-target="modal"]')
      const [data] = event.detail
      modalTemplate.innerHTML = data.content

      $(modalTemplate.firstChild).modal("show")
    })
  })
})
