import autocomplete from "./autocomplete"

const attachAutocomplate = () => {
  const input = document.querySelector('[data-input="autocomplete"]')
  const inputUrl = input.dataset.autocomplateHref

  autocomplete({
    input: input,
    source: (update) => {
      fetch(`${inputUrl}?n=${input.value}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => update(data))
        .catch((error) => console.log(error))
    },
    afterSelect: (data) => {
      document.getElementById("interest_rate_rate_id").value = data.id
      input.value = data.label
    },
  })
}

document.addEventListener("turbolinks:load", () => {
  const observeCallback = () => attachAutocomplate()

  const elementToObserve = document.getElementById("interest-rates-form")
  if (elementToObserve) {
    const observer = new MutationObserver(observeCallback)
    observer.observe(elementToObserve, {
      attribute: false,
      childList: true,
      subtree: false,
    })
  }
})
