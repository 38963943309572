import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['main', 'other', 'otherRadio']

  disableAdditionalOption(event) {
    console.log("fired 1");
    this.otherTarget.disabled = true
    this.otherRadioTarget.checked = false
  }

  removeMainOption(event) {
    console.log("fired");
    this.otherTarget.disabled = false
    this.otherTarget.focus()
    this.otherRadioTarget.checked = true
    this.mainTargets.forEach((field) => {
      field.checked = false
    })
  }
}
