import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["tableLookup", "monthlyDeposit"]

  static values = {
    depositType: String,
  }

  calculationForm(event) {
    const [data] = event.detail
    this.tableLookupTarget.innerHTML = data
  }

  changeInitialDeposit({ currentTarget }) {
    const { value } = currentTarget

    if (this.depositTypeValue == "default") {
      this.monthlyDepositTarget.value = value
    }
  }
}
