import autocomplete from "./autocomplete"
import attachFileinput from "./custom_file_input"

const attachAutocomplate = () => {
  const inputTargetId = document.getElementById("slider_target_id")
  const inputTargetType = document.getElementById("slider_target_type")
  const input = document.querySelector('[data-input="autocomplete"]')
  const inputUrl = input.dataset.autocomplateHref

  autocomplete({
    input: input,
    source: (update) => {
      fetch(`${inputUrl}?t=${input.value}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => update(data))
        .catch((error) => console.log(error))
    },
    afterSelect: (data) => {
      inputTargetId.value = data.target_id
      inputTargetType.value = data.target_type
      input.value = data.label
    },
  })
}

document.addEventListener("turbolinks:load", () => {
  const observeCallback = () => {
    const inputFile = document.querySelector('[data-target="custom-file-input"]')

    attachAutocomplate()
    attachFileinput(inputFile)
  }

  const elementToObserve = document.getElementById("main-slider-form")
  if (elementToObserve) {
    const observer = new MutationObserver(observeCallback)
    observer.observe(elementToObserve, {
      attribute: false,
      childList: true,
      subtree: false,
    })
  }
})
