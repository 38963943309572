import attachFileinput from "./custom_file_input"

const addField = (event, template, items) => {
  event.preventDefault()
  const content = template.innerHTML.replace(/TEMPLATE_RECORD/g,new Date().valueOf())
  items.insertAdjacentHTML("beforeend", content)
}

const deleteField = (event) => {
  event.preventDefault()
  let item = event.currentTarget.closest('.nested-form-fields')
  item.querySelector("input[name*='_destroy']").value = 1
  item.style.display = 'none'
}

document.addEventListener("turbolinks:load", () => {
  const controller = document.querySelector('[data-controller="nested-form"]')

  if (!controller) return

  const items = controller.querySelector('[data-target="nested-form.fields"]')
  const template = controller.querySelector('[data-target="nested-form.template"]')

  controller
    .querySelector('[data-action="nested-form#addField"]')
    .addEventListener("click", (event) => addField(event, template, items))

  controller
    .querySelectorAll('[data-action="nested-form#deleteField"]')
    .forEach(el => el.addEventListener("click", (event) => deleteField(event)))

  const observer = new MutationObserver((mutations) => {
    const buttons = document.querySelectorAll('[data-target="custom-file-input"]')
    attachFileinput(buttons[buttons.length - 1])

    const addedNode = mutations[0].addedNodes[0]
    addedNode
      .querySelector('[data-action="nested-form#deleteField"]')
      .addEventListener("click", (event) => deleteField(event))
  })

  observer.observe(items, {
    attribute: false,
    childList: true,
    subtree: false,
  })
})
