import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['errorsField']

  onSuccess(event) {
    const errorClasses = ['alert', 'alert-danger']
    let [data, status, xhr] = event.detail;
    this.errorsFieldTarget.classList.add(...errorClasses)
    this.errorsFieldTarget.innerHTML = data.errors.join('<br>')
    window.scrollTo(0, 0);
  }
}
