import Rails from '@rails/ujs';

const updateCheckbox = (event) => {
  const url = event.currentTarget.dataset.publishedHref
  Rails.ajax({ type: "PATCH", url: url })
}

document.addEventListener("turbolinks:load", () => {
  document.querySelectorAll('[data-action="published-slider"]').forEach(el => {
    el.addEventListener("change", (event) => updateCheckbox(event))
  })
})
