const attachFileinput = (input) => {
  if (!input) return

  input.addEventListener("change", (event) => {
    const name = input.files[0].name
    const nextSibling = event.target.nextElementSibling
    nextSibling.innerText = name
  })
}

export default attachFileinput

document.addEventListener("turbolinks:load", () =>
  attachFileinput(document.querySelector('[data-target="custom-file-input"]'))
)
