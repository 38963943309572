import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "initialDepositField",
    "monthlyDepositField",
    "periodDepositField",
    "initialDepositTemplate",
    "monthlyDepositTemplate",
    "periodDepositTemplate",
  ]

  addField({ currentTarget }) {
    const { fieldType } = currentTarget.dataset

    switch (fieldType) {
      case "initialDeposit":
        this._renderTemplate(this.initialDepositTemplateTarget, this.initialDepositFieldTarget)
        break
      case "monthlyDeposit":
        this._renderTemplate(this.monthlyDepositTemplateTarget, this.monthlyDepositFieldTarget)
        break
      case "periodDeposit":
        this._renderTemplate(this.periodDepositTemplateTarget, this.periodDepositFieldTarget)
        break
      default:
        throw new Error("invalid type when added new formula field")
    }
  }

  removeField({ currentTarget }) {
    const item = currentTarget.closest(".formula-field")
    item.querySelector("input[name*='_destroy']").value = 1
    item.style.display = "none"
  }

  _renderTemplate(template, fieldTarget) {
    const content = template.innerHTML.replace(/TEMPLATE_FIELDS/g, Math.floor(Date.now() * 2))
    fieldTarget.insertAdjacentHTML("beforeend", content)
  }
}
