import { Controller } from "stimulus";

export default class extends Controller {
  openModal({ detail }) {
    try {
      const [data] = detail;
      const el = document.getElementById("modal-tag");
      this._showModal(el, JSON.parse(data));
    } catch {
      // Error handler
    }
  }

  _showModal(element, data) {
    element.innerHTML = data.content;
    $(element.childNodes).modal("show");
  }
}
