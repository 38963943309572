import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["prizeRuleField", "prizeRuleTemplate"]

  addField() {
    const content = this.prizeRuleTemplateTarget.innerHTML
    this.prizeRuleFieldTarget.insertAdjacentHTML("beforeend", content)
  }

  removeField({ currentTarget }) {
    currentTarget.closest(".formula-field").remove()
  }
}