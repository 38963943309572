const autocomplete = ({ input, source, minLength, afterSelect }) => {
  minLength = minLength || 3

  input.addEventListener("input", (event) => {
    let node = event.currentTarget
    let value = node.value

    closeAllLists()
    if (!value && value.length < minLength) return false

    let itemsContainer = document.createElement("div")
    itemsContainer.setAttribute("id", "autocomplete-menu")
    itemsContainer.classList.add("dropdown")
    node.parentNode.appendChild(itemsContainer)

    const update = (resource) => {
      resource.forEach((data) => {
        if (data.label.toLowerCase().includes(value.toLowerCase())) {
          let item = document.createElement("div")
          item.classList.add("dropdown-item")
          item.innerHTML = data.label

          item.addEventListener("click", () => {
            if (afterSelect) {
              afterSelect(data)
            } else {
              input.value = data.value
            }
            closeAllLists()
          })

          itemsContainer.appendChild(item)
        }
      })
    }

    typeof source === "function" ? source(update) : update(source)
  })

  const closeAllLists = () => {
    try {
      document.querySelector("#autocomplete-menu").remove()
    } catch {
      false
    }
  }

  document.addEventListener("click", () => closeAllLists())
}

export default autocomplete
