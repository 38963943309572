import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["periodIndex", "plafondIndex", "latestPeriod", "latestPlafond"];

  addPlafond({ detail }) {
    const [data] = detail;
    this.latestPlafondTarget.insertAdjacentHTML("beforebegin", data.content);
  }

  addPeriod({ detail }) {
    const [data] = detail;

    if (data.replaceable) {
      const periodField = this.periodIndexTarget.querySelector("th[data-period-index]");
      periodField.innerHTML = data.period_content;
    } else {
      this.latestPeriodTarget.insertAdjacentHTML("beforebegin", data.period_content);
      this.latestPlafondTarget.insertAdjacentHTML("beforeend", data.button_content);

      const plafondCells = this.plafondIndexTarget.querySelectorAll(`td[data-period-cell-index="${data.index - 1}"]`);
      data.cells_content.forEach((cell, index) => {
        const plafond = plafondCells[index];
        plafond.insertAdjacentHTML("afterend", cell);
      });
    }
  }

  removePeriod({ detail, currentTarget }) {
    const [data] = detail;
    const periodButtonIndex = currentTarget.dataset.periodButtonIndex;
    const periodField = this.periodIndexTarget.querySelector(`th[data-period-index="${periodButtonIndex}"]`);

    if (data.content === null) {
      const elements = this.plafondIndexTarget.querySelectorAll(`td[data-period-cell-index="${periodButtonIndex}"]`);

      elements.forEach((element) => element.remove());
      periodField.remove();
      currentTarget.closest("td").remove();
    } else {
      periodField.innerHTML = data.content;
    }
  }

  removePlafond({ currentTarget }) {
    const plafondButtonIndex = currentTarget.dataset.plafondButtonIndex;
    const element = this.plafondIndexTarget.querySelector(`tr[data-plafond-index="${plafondButtonIndex}"]`);
    element.remove();
  }
}
