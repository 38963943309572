// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Styles
import "styles/index.scss"

// Images
require.context('../images', true)

// Vendor
require("vendor/react-rails")
require("vendor/feather-icons")
require("vendor/jquery")
require("vendor/bootstrap")

// Stimulus
// import 'controllers'
require("trix")
require("@rails/actiontext")

require("../customs/modal")
require("../customs/autocomplete")
require("../customs/custom_file_input")
require("../customs/main_slider_form")
require("../customs/published_slider")
require("../customs/interest_product_form")
require("../customs/interest_rate_form")
require("../customs/nested_form")

import "controllers"
